export const UserLoginMessage = {
    LogginFailure: "Login Failed. Incorrect Email and Password.",
    LogginSuccess: "User logged in",
    LoginEmailBlank: "Enter Email Address",
    LoginPasswordBlank: "Enter Password",
    LoginInvalidEmail: "Enter valid Email",
    reCAPTCHAFail: "reCAPTCHA validation failed. Please try again."
};
export const Abstractor_Msgs = {
    FIRSTNAME: "Enter first name",
    LASTNAME: "Enter last name",
    EMAIL_REQUIRED: "Enter email address",
    EMAIL_VALID: "Please enter a valid email",
    CENTER: "Please select center",
    REQUIRED: "This field is required",
    PASSWORD_VALID:
        "Your password must contain one lowercase letter, one uppercase letter, one number, and one symbol",
    EMAIL_EXISTS: "Email address already exists",
    CENTER_LOAD_ERROR: "Error occurred while loading centers",
    ADD_SUCCESS: "Abstractor added successfully",
    EDIT_SUCCESS: "Abstractor updated successfully",
    DELETE_SUCCESS: "Abstractor deleted successfully",
    DELETE_FAIL: "Error occurred when deleting abstractor",
    EMAIL_SENDOUT_SUCCESS: "Email sent successfully",//"An email has been sent to the email address regarding the further process",
    EMAIL_SENDOUT_ERROR: "Email address does not exists"
};

export const Center_Msgs = {
    NAME: "Enter center name",
    ADD_SUCCESS: "Center added successfully",
    EDIT_SUCCESS: "Center updated successfully",
    DELETE_SUCCESS: "Center deleted successfully",
    DELETE_FAIL: "Error occurred when deleting center",
};

export const ResetPasswordMessages = {
    PASSWORD_BLANK: "Enter Password",
    CONFIRM_PASSWORD_BLANK: "Re-Enter Password",
    PASSWORD_MISMATCH: "Passwords did not match!",
    PASSWORD_INVALID:
        "Your password should include at least one lowercase letter, one uppercase letter, one number and one symbol with a minimum length of eight characters.",
    LINK_EXPIRED: "Link has expired",
    EMAIL_NOTEXISTS: "Email address does not exists",
    //100:"Some error!!! "
};

export const CommonError_Msgs = {
    LOAD_ERR: "Error occurred while loading.",
    SAVE_ERR: "Error occurred while saving.",
    REQUIRED: "This field is required.",
    DATE_BEFORE_1920: "Date entered is before 1920.",
    Hard_Empty: "Please ensure that all mandatory fields are filled out before proceeding.",
}
export const PatientScreening_Msgs = {
    EligiblePatient: "This patient chart meets the study eligibility criteria. Please proceed to the next section of the chart abstraction form.",
    IneligiblePatient: "Thank you for entering the information, however the selected patient chart is not eligible for this study. No further information for this patient will be collected.",
    INELIGIBLE: "This patient is not eligible",
    //hard
    //q2
    100: "The year entered for the patient's primary ITP diagnosis is before the patient's year of birth. Please revise your response.",
    //q8
    101: "The year entered for the latest date of the patient's complete medical records is after the patient's year of death. Please revise your response.",
    //q11
    102: "The date entered for the patient's date of death is before the date of AVA initiation. Please revise your response.",
    103: "The year entered for the patient's death is before the year of AVA initiation. Please revise your response.",
    //q3 Q3IndexLessThandiagnosis  
    111: "The date of treatment initiation with AVA is before the date of primary ITP diagnosis. Please revise your response.",

    //soft
    //q3
    104: "The date entered for AVA initiation is before July 2019. Please revise your response.",
    105: "The date entered for AVA initiation is after June 2024. Please revise your response.",
    106: "Please confirm that the date of AVA initiation for this patient is unknown. Otherwise, please revise your response.",
    107: "Please confirm that this patient was not treated with AVA. Otherwise, please revise your response.",
    108: "The patient must be 18 years or older at the time of AVA initiation. Please review your records and edit your response if needed.",
    //q8
    109: `Based on the date that was inputted for the end date of complete medical records, this patient does not have at least 6 months of follow-up time following the date of AVA initiation. Please revise your response if necessary.`,
    //q15
    110: "Please confirm the patient’s weight before proceeding.",

    //When field are left blank(Hard errors)
    Q1: "Please select the year of birth.",
    Q1InvalidYear: "Patient must be at least 18 years of age at the date of AVA initiation",
    Q2: "Please select the date of primary ITP diagnosis.",
    Q3: "Please select the date of treatment initiation with avatrombopag (AVA).",
    Q4: "Please specify if the patient has complete medical records for at least 3 months before they initiated AVA.",
    Q5: "Please specify if the patient has at least one platelet count record.",
    Q6: "Please sepcify if the patient has participated in any previous AVA clinical trials, including the AVA-401 study.",
    Q7: " Please specify if the patient has complete medical records for at least 6 months following AVA initiation.",
    Q8: "Please specify the latest date that you have complete medical records for this patient.",
    Q9: "Please specify if the patient is alive.",
    Q10: " Please specify if the patient die within 6 months of AVA initiation.",
    Q11: "Please select patient’s year of death.",
    Q12: " Please specify if the patient is still seen at your clinic.",
    Q13: "Please specify the primary reason why the patient is no longer with your clinic.",
    Q14: "Please specify the comorbidities the patient was diagnosed with.",
    Q15: "Please specify the patient’s weight.",

    Q15Unit: "Please specify the unit.",
    //Date val. hard err
    Q2AfterToday: "The date you entered for date of primary ITP diagnosis is after today's date. Please revise your response.",
    Q3AfterToday: "The date you entered for date of AVA initiation is after today's date. Please revise your response.",
    Q8AfterToday: "The date you entered for latest date of complete medical records is after today’s date. Please revise your response.",

    DateChangeAlert: "The dates modified could impact the validation in AVA treatment page. Please resave AVA treatment page.",
    Q8ABeforeQ3Date:"The date entered for latest date of complete medical records is before the date of AVA initiation. Please revise your response."
}

export const TreatmentHistory_Msg =
{
    Q25aEltrombopag: "The date entered for initiation of Eltrombopag is on or after the date of AVA initiation. Please revise your response.",
    Q25aRomiplostim: "The date entered for initiation of Romiplostim is on or after the date of AVA initiation. Please revise your response.",
    Q25aLusutrombopag: "The date entered for initiation of Lusutrombopag is on or after the date of AVA initiation. Please revise your response.",
    Q25eDoseAfterEltrombopag: "The date entered for the dose change of Eltrombopag is on or before the date of Eltrombopag initiation. Please revise your response.",
    Q25eDoseAfterRomiplostim: "The date entered for the dose change of Romiplostim is on or before the date of Romiplostim initiation. Please revise your response.",
    Q25eDoseAfterLusutrombopag: "The date entered for the dose change of Lusutrombopag is on or before the date of Lusutrombopag initiation. Please revise your response.",
    Q25eDoseBeforeEltrombopag: "The date entered for the dose change of Eltrombopag is on or after the date of AVA initiation. Please revise your response.",
    Q25eDoseBeforeRomiplostim: "The date entered for the dose change of Romiplostim is on or after the date of AVA initiation. Please revise your response.",
    Q25eDoseBeforeLusutrombopag: "The date entered for the dose change of Lusutrombopag is on or after the date of AVA initiation. Please revise your response.",

    Q25iIndex: "The date entered for discontinuation is after the date of AVA initiation. Please revise your response.",
    Q25iBeforeTherapyEltrombopag: "The date of discontinuation you entered for Eltrombopag is on or before the date of Eltrombopag initiation. Please revise your response.",
    Q25iBeforeTherapyRomiplostim: "The date of discontinuation you entered for Romiplostim is on or before the date of Romiplostim initiation. Please revise your response.",
    Q25iBeforeTherapyLusutrombopag: "The date of discontinuation you entered for Lusutrombopag is on or before the date of Lusutrombopag initiation. Please revise your response.",
    Q25iBeforeDoseEltrombopag: "The date of discontinuation you entered for Eltrombopag is on or before one of the dates of Eltrombopag dose changes. Please revise your response for either the date of discontinuation or a dose change.",
    Q25iBeforeDoseRomiplostim: "The date of discontinuation you entered for Romiplostim is on or before one of the dates of Romiplostim dose changes. Please revise your response for either the date of discontinuation or a dose change.",
    Q25iBeforeDoseLusutrombopag: "The date of discontinuation you entered for Lusutrombopag is on or before one of the dates of Lusutrombopag dose changes. Please revise your response for either the date of discontinuation or a dose change.",


    Q26aIVIG: "The date of initiation entered for Intravenous immunoglobulin (IVIG) is on or after the date of AVA initiation. Please revise your response.",
    Q26aimmunoglobulin: "The date of initiation entered for Anti-D immunoglobulin is on or after the date of AVA initiation. Please revise your response.",
    Q26bIVIG: "The end date entered for Intravenous immunoglobulin (IVIG) is before the start date entered. Please revise your response.",
    Q26bimmunoglobulin: "The end date entered for Anti-D immunoglobulin is before the start date entered. Please revise your response.",
    Q26aThreeMonthsIndexIVIG: "The date of initiation entered for Intravenous immunoglobulin (IVIG) is before the 3 month baseline period. Please revise your response.",
    Q26aThreeMonthsIndeximmunoglobulin: "The date of initiation entered for Anti-D immunoglobulin is before the 3 month baseline period. Please revise your response.",
    Q26bIVIGInvalidSoft: "The end date entered for Intravenous immunoglobulin (IVIG) is after the end of available patient medical records. Please revise your response.",
    Q26bimmunoglobulinInvalidSoft: "The end date entered for Anti-D immunoglobulin is after the end of available patient medical records. Please revise your response.",


    Q27a: "The date entered for initiation of fostamatinib disodium is on or after the date of AVA initiation. Please revise your response.",
    Q27dDoseBefore: "The date you entered for the fostamatinib dose change is on or after the date of AVA initiation. Please revise your response.",
    Q27dDoseAfter: "The date you entered for the fostamatinib dose change is on or before the date entered for fostamatinib initiation. Please revise your response.",
    Q27gIndexDate: "The date of discontinuation you entered for fostamatinib is on or after the date of AVA initiation. Please revise your response.",
    Q27gBeforeTherapyDate: "The date of discontinuation you entered for fostamatinib is on or before the date of therapy initiation. Please revise your response",
    Q27gBeforeDoseDate:"The date of discontinuation you entered for fostamatinib disodium is on or before one of the dates of fostamatinib disodium dose changes. Please revise your response for either the date of discontinuation or a dose change.",

    Q28a: "The date entered for initiation of steroids is on or after the date of AVA initiation. Please revise your response.",
    Q28eDoseAfter: "The date entered for the dose change of steroids is on or before the date of AVA initiation. Please revise your response.",
    Q28eDoseBefore: "The date entered for the dose change of steroids is on or before the date of therapy initiation. Please revise your response.",
    Q28jLessIndexDate: "The date of discontinuation entered for this steroid course is on or after the date of AVA initiation. Please revise your response.",
    Q28jAfterIndexDate: "The date of discontinuation entered for this steroid course is on or before the date of initiation of this steroid course. Please revise your response.",
    Q28jBeforeDoseDate:"The date of discontinuation inputted for this steroid treatment is on or before one of the dates of the steroid dose changes. Please revise your response for either the date of discontinuation or a steroid dose change.",

    Q30: "The date entered for the patient's splenectomy is on or after the date of AVA initiation. Please revise your response.",

    QdosageFreq: "Please only input values between 1 - 1500.",

    Q24: "Please specify if the patient received any ITP treatments in the time periods specified.",
    Q24a: "Please specify if the treatment was ever used.",
    Q24b: "Please specify if the treatment was used in the 3 months prior to AVA initiation.",
    Q25aEltrombopagDate: "Please specify the date of Eltrombopag initiation.",
    Q25aRomiplostimDate: "Please specify the date of Romiplostim initiation.",
    Q25aLusutrombopagDate: "Please specify the date of Lusutrombopag initiation.",
    Q25bEltrombopag: "Please specify the dosage of Eltrombopag initiation.",
    Q25bRomiplostim: "Please specify the dosage of Romiplostim initiation.",
    Q25bLusutrombopag: "Please specify the dosage of Lusutrombopag initiation.",
    Q25cEltrombopag: "Please specify the frequency of Eltrombopag initiation.",
    Q25cRomiplostim: "Please specify the frequency of Romiplostim initiation.",
    Q25cLusutrombopag: "Please specify the frequency of Lusutrombopag initiation.",
    Q25d: "Please specify if the dose and/or frequency changed at any time.",
    Q25efg: "Please enter the date of the dose change and the new dose and frequency.",
    Q25f: "Please enter the dosage.",
    Q25g: "Please enter the frequency.",
    Q25e: "Please enter the date of the dose change.",
    Q25hEltrombopag: "Please specify if the pateint discontinued Eltrombopag at any time.",
    Q25hRomiplostim: "Please specify if the pateint discontinued Romiplostim at any time.",
    Q25hLusutrombopag: "Please specify if the pateint discontinued Lusutrombopag at any time.",
    Q25iEltrombopag: "Please enter the date of Eltrombopag end/discontinuation.",
    Q25iRomiplostim: "Please enter the date of Romiplostim end/discontinuation.",
    Q25iLusutrombopag: "Please enter the date of Lusutrombopag end/discontinuation.",
    Q26aIVIGStart: "Please specify the start date for Intravenous immunoglobulin (IVIG).",
    Q26aimmunoglobulinStart: "Please specify the start date for Anti-D immunoglobulin.",
    Q26bIVIGEnd: "Please specify the end date for Intravenous immunoglobulin (IVIG).",
    Q26bimmunoglobulinEnd: "Please specify the end date for Anti-D immunoglobulin.",
    Q27aDate: "Please specify the date of fostamatinib disodium initiation.",
    Q27b: "Please specify the starting dose for fostamatinib disodium.",
    Q27c: "Please specify if the dose and/or frequency changed at any time.",
    Q27de: "Please specify the date of dose change and the new dose and frequency.",
    Q27d: "Please specify the date of dose change.",
    Q27e: "Please specify the new dose and frequency.",
    Q27f: "Please specify if the patient discontinued fostamatinib disodium at any time.",
    Q27g: "Please enter the date of fostamatinib disodium discontinuation.",
    Q28aSteroid: "Please specify the type of steroid received.",
    Q28b: "Please specify the date of initiation of steroids.",
    Q28c: "Please specify the initial dosage.",
    Q28d: "Please specify the initial frequency.",
    Q28e: "Please specify if the dose changed during this course of steroids at any time prior to AVA initiation.",
    Q28fgh: "Please enter the date of the dose change and the new dose and frequency.",
    Q28f: "Please enter the date of the dose change.",
    Q28g: "Please enter the dosage.",
    Q28h: "Please enter the frequency.",
    Q28i: "Please specify if the patient discontinued this course of steriods prior to AVA initiation.",
    Q28j: "Please specify the Date of steroid end/discontinuation.",
    Q29Rituximab: "Please specify if the patient was still on Rituximab at the time of AVA initiation.",
    Q29Immunosuppressants: "Please specify if the patient was still on Immunosuppressants  at the time of AVA initiation.",
    Q30: "Please specify if the patient has ever received a splenectomy prior to initiation of AVA.",
    Q30a: "Please specify the date of splenectomy."


}

export const MessageRequired_Msg = {
    Q24: "please select any one option for treatment",
    Required: "This field is required."
}


export const AvaTreatment_Msgs = {
    DateAVAdiscontinue: "The date entered for AVA discontinuation is on or before the date of AVA initiation. Please revise your response.",
    DateDosageChange: "The date entered for the AVA dosage and/or frequency change is on or before the date of AVA initiation. Please revise your response. ",
    PlateletlessthanIndex: "The date entered for this platelet count is earlier than 3 months prior to the date of AVA initiation. Please revise your response.",
    PlateletmorethanAVAdisc: "The date entered for this platelet count is after the patient's date of AVA discontinuation. Please revise your response.",
    PlateletmorethanEndFollowup: "The date entered for this platelet count is after the end of available patient medical records. Please revise your response.",
    Q31: "Please specify if AVA was used as 1st line treatment for ITP.",
    Q32a: "Please specify the reasons for initiating AVA for the treatment of primary ITP.",
    Q32b: "Please specify the primary reason for initiating AVA.",
    Q33: "Please specify the patient’s starting AVA dosage and frequency.",
    Q34a: "Please select the Date of change.",
    Q34b: "Please specify the New dosage.",
    Q34c: "Please specify the New frequency.",
    Q34d: "Please specify the primary reason for the change in AVA dosage and / or frequency.",
    Q35: "Please specify if the patient discontinue AVA.",
    Q35a: "Please specify the date of discontinuation.",
    Q36: "Please specify the primary reason for discontinuing AVA treatment.",
    Q37a: "Please specify the platelet count dates.",
    Q37b: "Please specify the platelet count values.",
    QdosageFreq: "Please only input values between 1 - 1500",
    Qplatelet: "Please only input values between 0 - 2000",
    Q35BeforeDoseDate: "The date of discontinuation inputted for AVA is on or before one of the dates of AVA dose changes. Please revise your response for either the date of discontinuation or an AVA dose change.",
    Q37EmptyPlatelet: "Please enter platelet count for the date entered or delete the row if you have no other records of patient’s platelet counts.",
    Q37EmptyDate: "Please enter the date this platelet count was recorded on or delete the row if you have no other records of patient’s platelet counts.",
    Q32EmptyOtherText: "Please specify the other value.",
    Q38a: "Please specify the platelet transfusion dates.",
    TransfusionlessthanIndex: "The date entered for this platelet transfusion is earlier than 3 months prior to the date of AVA initiation. Please revise your response.",
    TransfusionmorethanAVAdisc: "The date entered for this platelet transfusion is after the patient's date of AVA discontinuation. Please revise your response.",
    TransfusionmorethanEndFollowup: "The date entered for this platelet transfusion is after the end of available patient medical records. Please revise your response.",
    FutureDate: "The date you entered for is after today’s date. Please revise your response.",
    TansfusionUnknwnSelected: "Please confirm that the patient did not have any platelet transfusions during the time period of interest. Please note that, if you confirm, any information entered in the table will be cleared.",
    Q34DateInvalidSoft: "The date of change for Dose/Frequency is after the end of available patient medical records. Please revise your response.",
    Q35DateInvalidSoft: "The date of AVA discontinuation is after the end of available patient medical records. Please revise your response.",

}

export const TreatmentAfterAVA_Msgs = {
    Q38aEmpty: "Please specify the Type of steroid received.",
    Q38bEmpty: "Please specify change(s) in steroid dose/frequency or discontinuation.",
    Q38RepeaterEmpty: "Please enter the date of the dose change and the new dose and frequency.",
    Q38RepeaterbEmpty: "Please specify the date of dose change.",
    Q38RepeatercEmpty: "Please enter the dosage.",
    Q38RepeaterdEmpty: "Please enter the frequency.",
    Q38eEmpty: "Please specify if the patient discontinued steroids.",
    Q38fEmpty: "Please specify date of steroids discontinuation.",
    Q38gDateInvalidSoft4: "The date of discontinuation inputted for this steroid treatment is on or before one of the dates of the steroid dose changes. Please revise your response for either the date of discontinuation or a steroid dose change.",

    Q39EmptySteroid: "Please specify if the patient dose or frequency of steroid use.",
    Q39Empty: "Please specify if the patient initiate a course of steroids.",
    Q39aEmpty: "Please specify type of steroid received.",
    Q39bEmpty: "Please specify date of steroid initiation.",
    Q39bDateInvalidSoft1: "The date entered for steroid initiation is on or before the date of AVA initiation. Please revise your response.",
    Q39bDateInvalidSoft2: "The date entered for this steroid initiation is after the patient's date of AVA discontinuation. Please revise your response.",
    Q39bDateInvalidSoft3: "The date entered for this steroid initiation is after the end of available patient medical records. Please revise your response.",
    Q39cEmpty: "Please specify dosage.",
    Q39dEmpty: "Please specify frequency.",
    Q39eEmpty: "Please specify if the patient’s steroid dose or frequency.",
    Q39fEmpty: "Please specify date of dose change.",
    Q39fDateInvalidSoft1: "The date entered for steroid dose change is on or before the the date of steroid initiation entered. Please revise your response.",
    Q39fDateInvalidSoft2: "The date entered for this steroid dose change is after the patient's date of AVA discontinuation. Please revise your response.",
    Q39fDateInvalidSoft3: "The date entered for this steroid dose change is after the end of available patient medical records. Please revise your response.",
    Q39gEmpty: "Please specify dosage.",
    Q39hEmpty: "Please specify frequency.",
    Q39iEmpty: "Please specify if patient discontinue steroids following AVA initiation.",
    Q39jEmpty: "Please specify date of discontinuation.",
    Q39jDateInvalidSoft1: "The date entered for steroid discontinuation is before the the date of steroid initiation entered. Please revise your response.",
    Q39jDateInvalidSoft2: "The date entered for this steroid discontinuation is after the patient's date of AVA discontinuation. Please revise your response.",
    Q39jDateInvalidSoft3: "The date entered for this steroid discontinuation is after the end of available patient medical records. Please revise your response.",

    //Q38-Steroid
    Q39FDateInvalidSoft1: "The date entered for steroid discontinuation is on or before the date of AVA initiation. Please revise your response.",
    Q39FDateInvalidSoft2: "The date entered for this steroid discontinuation is after the patient's date of AVA discontinuation. Please revise your response.",
    Q39FDateInvalidSoft3: "The date entered for this steroid discontinuation is after the end of available patient medical records. Please revise your response.",

    Q39BSteroidDateInvalidSoft1: "The date entered for this steroid dose change is before the date of AVA initiation. Please revise your response.",
    Q39BSteroidDateInvalidSoft2: "The date entered for this steroid dose change is after the patient's date of AVA discontinuation. Please revise your response.",
    Q39BSteroidDateInvalidSoft3: "The date entered for this steroid dose change is after the end of available patient medical records. Please revise your response.",


    Q41Empty: "Please specify if the the patient use IVIG or anti-D immunoglobulin.",

    Q41iviaEmpty: "Please enter the date of dose start for IVIG.",
    Q41iviaDateInvalidSoft1: "The date entered for [therapy] initiation is before the date of AVA initiation. Please revise your response.",
    Q41iviaDateInvalidSoft2: "The date entered for [therapy] initiation is after the patient's date of AVA discontinuation. Please revise your response.",
    Q41iviaDateInvalidSoft3: "The date entered for [therapy] is after the end of available patient medical records. Please revise your response.",

    Q41ivibEmpty: "Please enter the date of dose end for IVIG.",
    Q41ivibDateInvalidSoft1: "The end date entered for [therapy] is on or before the date of AVA initiation. Please revise your response.",
    Q41ivibDateInvalidSoft2: "The end date entered for [therapy] is after the patient's date of AVA discontinuation. Please revise your response.",
    Q41ivibDateInvalidSoft3: "The end date entered for [therapy] is after the end of available patient medical records. Please revise your response.",

    Q41antidaEmpty: "Please enter the date of dose start for Anti-D immunoglobulin.",
    Q41antidaDateInvalidSoft1: "The date entered for [therapy] initiation is before the date of AVA initiation. Please revise your response.",
    Q41antidaDateInvalidSoft2: "The date entered for  [therapy] initiation is after the patient's date of AVA discontinuation. Please revise your response.",
    Q41antidaDateInvalidSoft3: "The date entered for [therapy] is after the end of available patient medical records. Please revise your response.",

    Q41antidbEmpty: "Please enter the date of dose end for Anti-D immunoglobulin.",
    Q41antidbDateInvalidSoft1: "The end date entered for [therapy] is on or before the date of AVA initiation. Please revise your response.",
    Q41antidbDateInvalidSoft2: "The end date entered for [therapy] is after the patient's date of AVA discontinuation. Please revise your response.",
    Q41antidbDateInvalidSoft3: "The end date entered for [therapy] is after the end of available patient medical records. Please revise your response.",

    Q42Empty: "Please specify discontinuation of other ITP treatments.",
    Q42Date1Empty: "Please specify date of discontinuation for Rituximab.",
    Q42Date2Empty: "Please specify date of discontinuation for Immunosuppressants.",
    Q42DateInvalidSoft1: "The discontinuation date entered for [therapy] is before the date of AVA initiation. Please revise your response.",
    Q42DateInvalidSoft2: "The discontinuation date entered for [therapy] is after the patient's date of AVA discontinuation. Please revise your response.",
    Q42DateInvalidSoft3: "The discontinuation date entered for [therapy] is after the end of available patient medical records. Please revise your response.",

    Q43Empty: "Please specify initiation of other ITP treatments.",

    Q43aEmpty: "Please enter the date of initiation for [therapy].",
    Q43bEmpty: "Please specify if the Patient discontinued [therapy].",
    Q43cEmpty: "Please enter the date of discontinuation for [therapy].",

    Q43Date1InvalidSoft1: "The date of initiation entered for [therapy] is before the date of AVA initiation. Please revise your response.",
    Q43Date1InvalidSoft2: "The date of initiation entered for [therapy] is after the patient's date of AVA discontinuation. Please revise your response.",
    Q43Date1InvalidSoft3: "The date of initiation entered for [therapy] is after the end of available patient medical records. Please revise your response.",

    Q43Date2InvalidSoft1: "The discontinuation date entered for [therapy] is on or before the date of initiation. Please revise your response.",
    Q43Date2InvalidSoft2: "The discontinuation date entered for [therapy] is after the patient's date of AVA discontinuation. Please revise your response.",
    Q43Date2InvalidSoft3: "The discontinuation date entered for [therapy] is after the end of available patient medical records. Please revise your response.",

    Q44Empty: "Please specify if the patient received a splenectomy at any time after AVA initiation.",
    Q44DataEmpty: "Please specify the date of splenectomy.",
    Q44DateInvalidSoft1: "The date of the patient's splenectomy is on or before the date of AVA initiation. Please revise your response.",
    Q44DateInvalidSoft2: "The date of the patient's splenectomy is after the patient's date of AVA discontinuation. Please revise your response.",
    Q44DateInvalidSoft3: "The date of the patient's splenectomy is after the end of available patient medical records. Please revise your response.",

    Q45DateInvalidSoft1: "The date entered for initiation of [therapy] is before the date of AVA discontinuation. Please revise your response.",
    Q45DateInvalidSoft2: "The date entered for initiation of [therapy] is after the end of available patient medical records. Please revise your response.",
    Q45EmptyErrorMsg: "Please specify if the patient switched to any of the following ITP treatments following AVA discontinuation.",

    Q45a1EmptyErrorMsg: "Please enter the date of initiation for [Therapy].",
    Q45bEmptyErrorMsg: "Please specify the primary reason the patient switched to this treatment following AVA discontinuation.",
    Q45baEmptyErrorMsg: "Please enter the value for other specify.",

    QdosageFreq: "Please only input values between 1 - 1500.",
    Q45OtherItpTmtEmptyErrorMsg: "Please specify the other ITP treatment.",
}

export const HRU_Msgs = {
    Q45: "Please specify if the patient have any ITP-related hospitalizations",
    Q45RepeaterA: "Please enter date of admission of ITP-related hospitalizations",
    Q45RepeaterADateInvalidSoft1: "The date entered for this ITP-related hospitalization admission is earlier than 3 months prior to the date of AVA initiation. Please revise your response.",
    Q45RepeaterADateInvalidSoft2: "The date entered for this ITP-related hospitalization admission is after the patient's date of AVA discontinuation. Please revise your response.",
    Q45RepeaterADateInvalidSoft3: "The date entered for this ITP-related hospitalization admission is after the end of available patient medical records. Please revise your response.",

    Q45RepeaterB: "Please enter date of discharge of ITP-related hospitalizations",
    Q45RepeaterBDateInvalidSoft1: "The date entered for this ITP-related hospitalization discharge is earlier than the date of admission. Please revise your response.",
    Q45RepeaterBDateInvalidSoft2: "The date entered for this ITP-related hospitalization admission is after the end of available patient medical records. Please revise your response.",

    Q46: "Please specify if the patient have any ITP-related emergency room (ER) visits",
    Q46Repeater: "Please enter the date of ITP-related emergency visits",
    Q46RepeaterADateInvalidSoft1: "The date entered for this ITP-related ER visit is earlier than 3 months prior to the date of AVA initiation. Please revise your response.",
    Q46RepeaterADateInvalidSoft2: "The date entered for this ITP-related ER visit is after the patient's date of AVA discontinuation. Please revise your response.",
    Q46RepeaterADateInvalidSoft3: "The date entered for this ITP-related ER visit is after the end of available patient medical records. Please revise your response.",

    Q47: "Please specify if the patient have any ITP-related outpatient (OP) visits",
    Q47Repeater: "Please enter the date of ITP-related outpatient visits",
    Q47RepeaterADateInvalidSoft1: "The date entered for this ITP-related OP visit is earlier than 3 months prior to the date of AVA initiation. Please revise your response.",
    Q47RepeaterADateInvalidSoft2: "The date entered for this ITP-related OP visit is after the patient's date of AVA discontinuation. Please revise your response.",
    Q47RepeaterADateInvalidSoft3: "The date entered for this ITP-related OP visit is after the end of available patient medical records. Please revise your response.",
    QdosageFreq: "Please only input values between 1 - 1500",
}

export const demographicScreen_Msgs = {
    Q16: "Please select the patient's sex.",
    Q17: "Please select  the patient's race/ethnicity.",
    Q17a: "Please specify the other value.",
    Q18: "Please specify the state the patient reside in.",
    Q19: "Please select highest level of education the patient has completed.",
    Q20: "Please select patient's household income.",
    Q21: "Please select patient's employment status.",
    Q22: "Please select type(s) of insurance does the patient currently have. ",
    Q23: "Please select patient's primary language spoken at home.",
    Q23a: "Please specify  the other value.",
}

export const ForgotPassword = {
    EmailNotExistent: "This Email Address has not been set up - Please contact your Administrator to set this up.",
    EmailSuccess: "An Email has been sent to you regarding the further process.",
    ErrorOccurred: "Error occurred."
};
export const CommonMsgs = {
    DeleteConfirmMsg: "Are you sure you want to delete this data?",
    SessionTimeout: "Your session has expired, you will be logged out now.",
    SubmitSuccess: "Patient Submitted Successfully"
}
export const PatientDashboard_Msgs = {
    ExportSuccess: "Data exported successfully",
    UnlockConfirmMsg: "Would you like to unlock and allow the abstractors to edit this patient?",
    UnlockSuccessMsg: "Patient Unlocked successfully",
}